import Vue from 'vue';
import Vuetify from 'vuetify/lib'
import config from '../configs'
import * as directives from 'vuetify/lib/directives'


import SvgIcon from "@/components/SvgIcon.vue"

Vue.use(Vuetify, {
    directives
});


const MY_ICONS = {
    checkboxOn: {
        component: SvgIcon,
        props: {
            svgPath: "checkboxChecked.svg",
        }
    },
    checkboxOff: {
        component: SvgIcon,
        props: {
            svgPath: "checkboxOff.svg",
        }
    },
    checked: {
        component: SvgIcon,
        props: {
            svgPath: "checkboxChecked.svg",
        }
    },
    unchecked: {
        component: SvgIcon,
        props: {
            svgPath: "checkboxOff.svg",
        }
    },
    checkboxIndeterminate: {
        component: SvgIcon,
        props: {
            svgPath: "checkboxIndeterminate.svg",
        }
    },
    //Untitled Icons
}

const untitledIcons = require("../scripts/untitledIcons.js").default();

export default new Vuetify({
    theme: {
        dark: config.theme.globalTheme === 'dark',
        options: {
            customProperties: true
        },
        themes: {
            dark: config.theme.dark,
            light: config.theme.light
        },

    },
    icons: {
        iconfont: 'mdi',
        values: { ...MY_ICONS, ...untitledIcons },
    }
})