export default {
  // global theme for the app #6141DB
  globalTheme: 'light', // light | dark

  // dark theme colors
  dark: {
    textbase: '#ffd166',
    background: '#05090c',
    surface: '#111b27',
    primary: '#6141db',
    secondary: '#5c7f94',
    accent: '#59bbbb',
    error: '#F04438',
    info: '#2196F3',
    success: '#17B26A',
    warning: '#F79009'
  },

  // light theme colors
  light: {
    textbase: '#101828',
    background: '#F9FAFB',
    surface: '#F8F9FC',
    primary: '#6141db',
    secondary: '#345995',
    accent: '#59bbbb',
    error: '#F04438',
    info: '#2196F3',
    success: '#17B26A',
    warning: '#F79009'
  }
}

