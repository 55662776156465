<template>
    <v-snackbar right bottom dense :timeout="3000" v-model="$store.state.snackbarActive" elevation="0" class="snackBarBody"
        color="transparent">
        <div class="alertBody">
            <div class="d-flex">
                <div class="iconOuter"
                    :style="{ 'border-color': $store.state.snackbarMode === 'success' ? '#e7f5ee' : '#fcf0e6' }">
                    <div class="iconInner"
                        :style="{ 'border-color': $store.state.snackbarMode === 'success' ? '#b5dfcc' : '#f5d2b4' }">
                        <svg v-if="$store.state.snackbarMode === 'success'" xmlns="http://www.w3.org/2000/svg" width="20"
                            height="20" viewBox="0 0 20 20" fill="none">
                            <g clip-path="url(#clip0_6896_11922)">
                                <path
                                    d="M6.24996 9.99996L8.74996 12.5L13.75 7.49996M18.3333 9.99996C18.3333 14.6023 14.6023 18.3333 9.99996 18.3333C5.39759 18.3333 1.66663 14.6023 1.66663 9.99996C1.66663 5.39759 5.39759 1.66663 9.99996 1.66663C14.6023 1.66663 18.3333 5.39759 18.3333 9.99996Z"
                                    stroke="#079455" stroke-width="1.66667" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_6896_11922">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                            fill="none">
                            <path
                                d="M9.99996 6.66663V9.99996M9.99996 13.3333H10.0083M18.3333 9.99996C18.3333 14.6023 14.6023 18.3333 9.99996 18.3333C5.39759 18.3333 1.66663 14.6023 1.66663 9.99996C1.66663 5.39759 5.39759 1.66663 9.99996 1.66663C14.6023 1.66663 18.3333 5.39759 18.3333 9.99996Z"
                                stroke="#DC6803" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
                <div class="alertText">
                    {{ $store.state.snackbarText }}
                </div>
                <v-btn x-small icon @click="closeSnackbar" class="closeButton">
                    <v-icon size="20" color="#101828">mdi-close</v-icon>
                </v-btn>
            </div>
            <div class="alertDescription">
                {{ $store.state.snackbarDescription }}
            </div>
        </div>
    </v-snackbar>
</template>

<script>
export default {
    methods: {
        closeSnackbar() {
            this.$store.commit("closeSnackbar");
        },
        openRoute(route) {
            this.$router.push(route);
        }
    }

}
</script>

<style lang="scss" scoped>
.alertBody {
    border-radius: 12px;
    border: 1px solid var(--gray-200, #EAECF0);
    background: var(--base-white, #FFF);
    /* Shadow/lg */
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    display: flex;
    padding: 16px;
    align-items: flex-start;
    flex-direction: column;
    align-self: stretch;
    //for absolute alignment of close button
    position: relative;
}

.iconOuter {
    position: relative;
    top: -10px;
    left: -10px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #e7f5ee;
}

.iconInner {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #b5dfcc;
}

.alertText {
    color: var(--gray-900, #101828);
    position: relative;
    top: -3px;
    width: 100%;
    font-size: 14px;

    font-style: normal;
    font-weight: 600;
    line-height: 20px;

    position: relative;
    z-index: 1;
    /* Lower z-index than close button */
    //margin right to not overlap with close button
    margin-right: 20px;
}

.alertDescription {
    color: var(--gray-600, #475467);
    position: relative;
    top: -10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 38px;
    /* 142.857% */
}

.closeButton {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    margin: 10px;
}
</style>