import Vue from 'vue';
import store from '../store/index';
export default function joinRooms() {
    const token = store.getters.token();
    if (!token) {
        console.log('No token found. Not joining rooms.');
        return;
    }
    Vue.prototype.$socket.emit('joinRooms', store.getters.token());
    console.log('Joining rooms');
}
