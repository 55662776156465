import SvgIcon from "@/components/SvgIcon.vue"

export default function () {
    return {

        fileDownload02: {
            component: SvgIcon,
            props: {
                svgPath: "file-download-02.svg",
            }
        },

        meta: {
            component: SvgIcon,
            props: {
                svgPath: "meta.svg",
            }
        },

        checkCircle: {
            component: SvgIcon,
            props: {
                svgPath: "check-circle.svg",
            }
        },
        

        infoCircle: {
            component: SvgIcon,
            props: {
                svgPath: "info-circle.svg",
            }
        },

        link03: {
            component: SvgIcon,
            props: {
                svgPath: "link-03.svg",
            }
        },
        settings03: {
            component: SvgIcon,
            props: {
                svgPath: "settings-03.svg",
            }
        },

        arrowRight: {
            component: SvgIcon,
            props: {
                svgPath: "arrow-right.svg",

            },
        },

        dataflow01: {
            component: SvgIcon,
            props: {
                svgPath: "dataflow-01.svg",

            },
        },

        closeCircle: {
            component: SvgIcon,
            props: {
                svgPath: "x-circle.svg",

            },
        },
        reverse: {
            component: SvgIcon,
            props: {
                svgPath: "reverse-left.svg",

            },
        },


        palette: {
            component: SvgIcon,
            props: {
                svgPath: "palette.svg",

            },
        },

        send01: {
            component: SvgIcon,
            props: {
                svgPath: "send-01.svg",
            }
        },
        arrowNarrowRight: {
            component: SvgIcon,
            props: {
                svgPath: "arrow-narrow-right.svg",
            }
        },
        arrowLeft: {
            component: SvgIcon,
            props: {
                svgPath: "arrow-left.svg",
            }
        },
        share05: {
            component: SvgIcon,
            props: {
                svgPath: "share-05.svg",
            }
        },
        linkExternal01: {
            component: SvgIcon,
            props: {
                svgPath: "link-external-01.svg",
            }
        },

        close: {
            component: SvgIcon,
            props: {
                svgPath: "x-close.svg",
            }
        },
        file02: {
            component: SvgIcon,
            props: {
                svgPath: "file-02.svg",
            }
        },
        edit01: {
            component: SvgIcon,
            props: {
                svgPath: "edit-01.svg",
            },
        },
        videoRecorder: {
            component: SvgIcon,
            props: {
                svgPath: "video-recorder.svg",
            },
        },
        helpCircle: {
            component: SvgIcon,
            props: {
                svgPath: "help-circle.svg",
            }
        },
        home01: {
            component: SvgIcon,
            props: {
                svgPath: "home-01.svg",
            }
        },
        lifeBuoy01: {
            component: SvgIcon,
            props: {
                svgPath: "life-buoy-01.svg",
            }
        },
        settings01: {
            component: SvgIcon,
            props: {
                svgPath: "settings-01.svg",
            }
        },
        usersCheck: {
            component: SvgIcon,
            props: {
                svgPath: "users-check.svg",
            }
        },
        logOut01: {
            component: SvgIcon,
            props: {
                svgPath: "log-out-01.svg",
            }
        },
        trendUp02: {
            component: SvgIcon,
            props: {
                svgPath: "trend-up-02.svg",
            }
        },
        grid01: {
            component: SvgIcon,
            props: {
                svgPath: "grid-01.svg",
            }
        },
        list: {
            component: SvgIcon,
            props: {
                svgPath: "list.svg",
            }
        },
        plus: {
            component: SvgIcon,
            props: {
                svgPath: "plus.svg",
            }
        },
        bankNote03: {
            component: SvgIcon,
            props: {
                svgPath: "bank-note-03.svg",
            }
        },

        searchLg: {
            component: SvgIcon,
            props: {
                svgPath: "search-lg.svg",
            }
        },
        mail01: {
            component: SvgIcon,
            props: {
                svgPath: "mail-01.svg",
            }
        },
        globe03: {
            component: SvgIcon,
            props: {
                svgPath: "globe-03.svg",
            }
        },
        userEdit: {
            component: SvgIcon,
            props: {
                svgPath: "user-edit.svg",
            }
        },
        cursorClick02: {
            component: SvgIcon,
            props: {
                svgPath: "cursor-click-02.svg",
            }
        },
        downloadCloud02: {
            component: SvgIcon,
            props: {
                svgPath: "download-cloud-02.svg",
            }
        },
        clockFastForward: {
            component: SvgIcon,
            props: {
                svgPath: "clock-fast-forward.svg",
            }
        },
        filterLines: {
            component: SvgIcon,
            props: {
                svgPath: "filter-lines.svg",
            }
        },
        columns03: {
            component: SvgIcon,
            props: {
                svgPath: "columns-03.svg",
            }
        },
        upload01: {
            component: SvgIcon,
            props: {
                svgPath: "upload-01.svg",
            }
        },
        trash01: {
            component: SvgIcon,
            props: {
                svgPath: "trash-01.svg",
            }
        },
        copy05: {
            component: SvgIcon,
            props: {
                svgPath: "copy-05.svg",
            }
        },
        userPlus01: {
            component: SvgIcon,
            props: {
                svgPath: "user-plus-01.svg",
            }
        },
        archive: {
            component: SvgIcon,
            props: {
                svgPath: "archive.svg",
            }
        },
        filePlus02: {
            component: SvgIcon,
            props: {
                svgPath: "file-plus-02.svg",
            }
        },
        fileX02: {
            component: SvgIcon,
            props: {
                svgPath: "file-x-02.svg"
            }
        },
        usersX: {
            component: SvgIcon,
            props: {
                svgPath: "users-x.svg",
            }
        },
        checkGreen: {
            component: SvgIcon,
            props: {
                svgPath: "check-green.svg",
            }
        },
        shieldTick: {
            component: SvgIcon,
            props: {
                svgPath: "shield-tick.svg",
            }
        },
        minus: {
            component: SvgIcon,
            props: {
                svgPath: "minus.svg",
            }
        },
        checkBlue: {
            component: SvgIcon,
            props: {
                svgPath: "check-blue.svg",
            }
        },
        circleDotBlue: {
            component: SvgIcon,
            props: {
                svgPath: "circle-dot-blue.svg",
            }
        },
        check: {
            component: SvgIcon,
            props: {
                svgPath: "check.svg",
            }
        },
        clock: {
            component: SvgIcon,
            props: {
                svgPath: "clock.svg",
            }
        },
        uploadCloud02: {
            component: SvgIcon,
            props: {
                svgPath: "upload-cloud-02.svg",
            }
        },
        database01: {
            component: SvgIcon,
            props: {
                svgPath: "database-01.svg",
            }
        },
        download01: {
            component: SvgIcon,
            props: {
                svgPath: "download-01.svg",
            }
        },
        upload01: {
            component: SvgIcon,
            props: {
                svgPath: "upload-01.svg",
            }
        },
        phone: {
            component: SvgIcon,
            props: {
                svgPath: "phone.svg",
            }
        },
        user01: {
            component: SvgIcon,
            props: {
                svgPath: "user-01.svg",
            }
        },
        calendarPlus01: {
            component: SvgIcon,
            props: {
                svgPath: "calendar-plus-01.svg",
            }
        },
        userRight01: {
            component: SvgIcon,
            props: {
                svgPath: "user-right-01.svg",
            }
        },
        fileDownload03: {
            component: SvgIcon,
            props: {
                svgPath: "file-download-03.svg",
            }
        },
        tag01: {
            component: SvgIcon,
            props: {
                svgPath: "tag-01.svg",
            }
        },
        dotsVertical: {
            component: SvgIcon,
            props: {
                svgPath: "dots-vertical.svg",
            }
        },
        chevronSelectorVertical: {
            component: SvgIcon,
            props: {
                svgPath: "chevron-selector-vertical.svg"
            }
        },
        chevronUp: {
            component: SvgIcon,
            props: {
                svgPath: "chevron-up.svg"
            }
        },
        chevronDown: {
            component: SvgIcon,
            props: {
                svgPath: "chevron-down.svg"
            }
        },
        save01: {
            component: SvgIcon,
            props: {
                svgPath: "save-01.svg"
            }
        },
        eye: {
            component: SvgIcon,
            props: {
                svgPath: "eye.svg"
            }
        },
        eyeOff: {
            component: SvgIcon,
            props: {
                svgPath: "eye-off.svg"
            }
        },
        lock1: {
            component: SvgIcon,
            props: {
                svgPath: "lock-01.svg"
            }
        },
        key01: {
            component: SvgIcon,
            props: {
                svgPath: "key-01.svg"

            }
        },
        play: {
            component: SvgIcon,
            props: {
                svgPath: "play.svg"
            }
        },
        linkExternal2: {
            component: SvgIcon,
            props: {
                svgPath: "link-external-02.svg"
            }
        },
        messageXSquare: {
            component: SvgIcon,
            props: {
                svgPath: "message-x-square.svg"
            }
        },
        bracketsEllipses:{
            component: SvgIcon,
            props:{
                svgPath: "brackets-ellipses.svg"
            }
        }
    }
}
