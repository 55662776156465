//export one function that returns the data
export const getDataTableLeads = (table, tableLeads, addActions, activeFilters, activeStatus, isAgency) => {

    let tableHeaders = [...table.dataTableHeaders];
    if (tableHeaders.length < 2 || !tableHeaders) {
        tableHeaders = [
            { value: 'status', text: 'Status' },
            { value: 'name', text: 'Name' },
            { value: 'email', text: 'Email' },
            { value: 'phone', text: 'Phone' },
            { value: 'createdAt', text: 'Created At' },
        ]
    }

    if (addActions) {
        tableHeaders.push({ value: 'actions', text: 'Actions' });
    }

    const flatLeads = tableLeads.map((lead) => {
        const flatLead = {
            //add that as standard keys
            _id: lead._id,
            status: lead.status,
            name: lead.name,
            email: lead.email,
            phone: lead.phone,
            createdAt: lead.createdAt,
            updatedAt: lead.history[lead.history.length - 1].date,
            lastChange: lead.history[lead.history.length - 1].date,
            changes: lead.history.length,
        };
        const profile = lead.funnelData.profile;
        for (const entry in profile) {
            const entryData = profile[entry]
            const entryQuestion = entryData.title;
            flatLead[entryQuestion] = entryData.value;
        }
        return flatLead;
    });

    return { leads: flatLeads, headers: tableHeaders };
}