import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import io from 'socket.io-client';
import VueVirtualScroller from 'vue-virtual-scroller';
import * as Sentry from '@sentry/vue';
import joinRooms from './scripts/joinRooms';

import '/node_modules/flag-icons/css/flag-icons.min.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

Vue.use(VueVirtualScroller);

Vue.config.productionTip = false;

let endpoint = process.env.API_ENDPOINT;

//create socket connection
Vue.prototype.$socket = io(endpoint, {
    transports: ['websocket', 'polling'],
    withCredentials: true,
});

let lastReconnectTimeout = null;
let attempts = 0;

function retryWebsocketConnection() {
    if (Vue.prototype.$socket.connected) {
        attempts = 0;
        console.log('Websocket connection is already established. Not retrying.');
        return;
    }
    //lets check if we are already trying to reconnect
    if (lastReconnectTimeout) {
        console.log('Already trying to reconnect. Not retrying.');
        return;
    }
    if (!lastReconnectTimeout) {
        lastReconnectTimeout = setTimeout(() => {
            if (Vue.prototype.$socket.connected) {
                console.log('Websocket connection is already established. Not retrying.');
                lastReconnectTimeout = null;
                return;
            }
            attempts++;
            if (attempts > 5) {
                console.log('Too many attempts. Not retrying.');
                return;
            }

            //make api call to check if the token is still valid
            Vue.prototype.$api
                .get('test/noauth')
                .then((response) => {
                    console.log('API can be reached. Retrying websocket connection');
                    Vue.prototype.$socket.io.opts.transports = ['polling'];
                    Vue.prototype.$socket.connect();
                    lastReconnectTimeout = null;
                })
                .catch((error) => {
                    console.log('API cannot be reached. Not retrying websocket connection');
                    //redirect to 404 page
                    if (process.env.NODE_ENV !== 'development') {
                        router.push({ name: '404' }).catch(() => {});
                    }
                    lastReconnectTimeout = null;
                });
        }, 5000);
    }
}

Vue.prototype.$socket.on('connect_error', (error) => {
    console.log('Connection error', error);
    retryWebsocketConnection();
});

Vue.prototype.$socket.on('disconnect', () => {
    console.log('Disconnected');
    retryWebsocketConnection();
});

Vue.prototype.$socket.on('reconnect', () => {
    console.log('Reconnected');
    joinRooms();
});

Vue.prototype.$socket.on('connect', function () {
    console.log('Connected');
    joinRooms();
    attempts = 0;
});

function reloadWindowOnChunkError() {
    //save the timestamp of the last reload in the localstorage. If the last reload was less than 5 seconds ago we don't reload again
    let lastReload = localStorage.getItem('lastReload');
    if (!lastReload) {
        lastReload = 0;
    }
    if (Date.now() - lastReload > 5000) {
        localStorage.setItem('lastReload', Date.now());
        window.location.reload();
    } else {
        console.log('Last reload was less than 5 seconds ago. Not reloading again.');
    }
}

router.onError((error) => {
    //handels chunk load errors
    if (/ChunkLoadError:.*failed./i.test(error.message)) {
        reloadWindowOnChunkError();
    }
    //handels CSS chunk load errors
    else if (/Loading.*chunk.*failed./i.test(error.message)) {
        reloadWindowOnChunkError();
    }
});

//init sentry when in beta or production
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'beta') {
    Sentry.init({
        Vue,
        dsn: 'https://4dfccad07f7795d11cc23b53ef6969a0@o4505324250202112.ingest.sentry.io/4506570290167808',
        integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
        //dont send errors that are caused by the user navigating to the same page again
        beforeSend(event, hint) {
            const dontLogTexts = [
                'redirected',
                'avoided redundant navigation to current location',
                'navigation cancelled',
                'navigationduplicated',
            ];
            const errorMessage = hint.originalException.message;
            if (errorMessage === undefined || dontLogTexts.some((text) => errorMessage.toLowerCase().includes(text))) {
                return null;
            }
            return event; // Send the event
        },

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.04,
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        // Capture Replay for 1% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.01,
        replaysOnErrorSampleRate: 1.0,
    });
}

const app = new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');

export default app;
