<template>
    <div v-html="svgContent"></div>
</template>

<script>
import { svgCache, currentlyFetching } from './../assets/images/svgCache';

export default {
    props: {
        svgPath: { type: String, required: true },
    },
    data() {
        return {
            svgContent: '',
        }
    },
    name: "SvgIcon",
    async mounted() {
        await this.ensureSVG();
    },
    watch: {
        svgPath: async function () {
            await this.ensureSVG();
        }
    },
    methods: {
        async ensureSVG() {
            // Überprüfen, ob der Inhalt bereits im Cache vorhanden ist
            if (svgCache.has(this.svgPath)) {

                //
                this.svgContent = svgCache.get(this.svgPath);
                return;
            }

            try {
                // return promise if already fetching
                if (currentlyFetching.includes(this.svgPath)) {
                    return new Promise((resolve, reject) => {
                        const interval = setInterval(() => {
                            if (!currentlyFetching.includes(this.svgPath)) {
                                clearInterval(interval);

                                resolve(this.svgContent = svgCache.get(this.svgPath));
                            }
                        }, 100);
                    });
                }
                currentlyFetching.push(this.svgPath);
                //inhalt der svg als string in svgContent speichern
                const reqSvg = require(`./../assets/images/UntitledIcons/${this.svgPath}`);
                const response = await fetch(reqSvg);
                if (!response.ok) throw new Error('Error loading SVG icon');
                this.svgContent = (await response.text())
                    .replace(/\s+width="[^"]*"/, '')
                    .replace(/\s+height="[^"]*"/, '');
                svgCache.set(this.svgPath, this.svgContent);
                //remove from currentlyFetching
                currentlyFetching.splice(currentlyFetching.indexOf(this.svgPath), 1);
            } catch (error) {
                console.error(`Error loading SVG-Icon ${this.svgPath} : ${error.message}`);
            }
        }
    }
}
</script>
